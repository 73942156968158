<template>
  <div>
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form>
          <b-row>
            <b-col md="">
              <b-form-group label="Pesquisar Por:" label-for="pesquisarPor">
                <b-form-select id="pesquisarPor" v-model="pesquisar.tipoOpcoes" :options="tipoOpcoes" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input
                  id="descricao"
                  :placeholder="pesquisar.tipoOpcoes == 1 ? 'Nome' : pesquisar.tipoOpcoes == 2 ? 'Nº Contrato' : 'Matrícula'"
                  v-model="pesquisar.descricao"
                  v-on:keydown.enter="carregarGrid"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Status" label-for="status">
                <b-form-select id="status" :options="statusOpcoes" v-model="pesquisar.statusOpcoes" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataInicial"
                      type="text"
                      placeholder="DD/MM/AAAA"
                      v-model="pesquisar.dataInicial"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                      v-on:keydown.enter="carregarGrid()"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataInicialDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Data Final" label-for="dataFinal">
                <validation-provider
                  #default="{ errors }"
                  name="Data Final"
                  :rules="{
                    dataPassada: {
                      value: pesquisar.dataFinal,
                      dataParametro: pesquisar.dataInicial,
                      stringParametro: 'data inicial',
                    },
                  }"
                >
                  <b-input-group>
                    <cleave
                      id="dataFinal"
                      class="form-control"
                      type="text"
                      placeholder="DD/MM/AAAA"
                      v-model="pesquisar.dataFinal"
                      :options="options.cleaveDate"
                      :raw="false"
                      v-on:keydown.enter="carregarGrid()"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataFinalDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Categoria" label-for="categoria">
                <b-form-select id="categoria" :options="categoriaOpcoes" v-model="categoria" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Vendedor" label-for="vendedor">
                <v-select-pt id="vendedor" v-model="vendedor" :options="vendedores" label="name" />
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 25px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Pesquisar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 24px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportTable('pdf')">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportTable('excel')">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-if="items.length > 0">
      <div class="mb-1">
        <span v-show="items.length > 0"
          >Total de contratos encontrados:
          <b-badge variant="primary" pill>
            {{ totals.rows }}
          </b-badge> </span
        ><br />
        <span v-show="items.length > 0"
          >Contratos consolidados:
          <b-badge variant="primary" pill>
            {{ totals.consolidados }}
          </b-badge> </span
        ><br />
        <span v-show="items.length > 0"
          >Contratos pendentes:
          <b-badge variant="primary" pill>
            {{ totals.pendentes }}
          </b-badge> </span
        ><br />
        <span v-show="items.length > 0"
          >Valor total de contratos:
          <b-badge variant="primary" pill>
            {{ totals.valorTotal }}
          </b-badge>
        </span>
      </div>
      <b-table
        v-show="items.length > 0"
        striped
        responsive
        id="tableRelatoriosContratos"
        ref="tableRelatoriosContratos"
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isBusy"
        @change="currentPage = $event"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template #cell(statusFinanceiro)="finanrow">
          <b-badge pill :variant="status[0][finanrow.item.statusFinanceiro]">
            {{ finanrow.item.statusFinanceiro }}
          </b-badge>
        </template>
      </b-table>
      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totals.rows"
          :per-page="pesquisar.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <div>
        <relatorioFinanceiroContratoRetrato ref="exportarPDF" :parametro="parametroPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard,
    BPagination,
    BTab,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BTable,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BButton,
    BForm,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarDataWTime, formatarValorReais, formatarValorPorcentagem } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import relatorioFinanceiroContratoRetrato from '../relatorioFinanceiro/components/relatorioFinanceiroContratoRetrato.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import { asDefault } from './components/exportTableContratos'
  import { required, dataFutura, dataPassada } from '@validations'

  export default {
    components: {
      relatorioFinanceiroContratoRetrato,
      BCard,
      BPagination,
      dataFutura,
      required,
      dataPassada,
      BTab,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BTable,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BButton,
      BForm,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      BDropdown,
      BDropdownItem,
      VueHtml2pdf,
      BCardCode,
      formatarData,
      formatarDataWTime,
      generateAndDownloadPdf,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        categoria: 'TODOS',
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarData,
        formatarDataWTime,
        isBusy: false,

        //Search
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'Nº Contrato' },
          { value: 3, text: 'Matrícula' },
        ],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
          statusOpcoes: '',
        },
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Consolidado', text: 'Consolidado' },
          { value: 'Quitado', text: 'Quitado' },
          { value: 'Cancelado', text: 'Cancelado' },
          { value: 'Segurado Exonerado', text: 'Segurado Exonerado' },
          { value: 'Segurado Falecido', text: 'Segurado Falecido' },
        ],

        //table
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        totals: {
          rows: 0,
          consolidados: 0,
          pendentes: 0,
          valorTotal: 0,
        },
        items: [],
        status: [
          {
            Consolidado: 'success',
            Pendente: 'warning',
          },
        ],
        fields: [
          { key: 'codigo', label: 'Nº Contrato', sortable: true },
          { key: 'proposta.segurado.tomador.nomeCompleto', label: 'Nome Tomador', sortable: true },
          { key: 'proposta.segurado.matricula', label: 'Matrícula', sortable: true },
          {
            key: 'proposta.valorComIOF',
            label: 'Valor do Contrato',
            sortable: true,
            formatter: (value) => {
              return formatarValorReais(value)
            },
          },
          {
            key: 'taxas',
            label: 'Taxas',
            sortable: true,
            formatter: (value) => {
              return formatarValorPorcentagem(value)
            },
          },
          {
            key: 'valorParcela',
            label: 'Valor da Parcela',
            sortable: true,
            formatter: (value) => {
              return formatarValorReais(value)
            },
          },
          { key: 'numeroParcelas', label: 'Nº de Parcelas', sortable: true },
          {
            key: 'createdAt',
            label: 'Data de início',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
            sortable: true,
          },
          'statusFinanceiro',
          //{ key: 'observacao', label: 'Observações' },
          { key: 'proposta.segurado.orgao.nome', label: 'Nome do Órgão', sortable: true },
        ],

        // PDF
        parametroPDF: {
          dados: [],
          colunas: [],
          institutoNome: '',
        },
        fieldsPDF: [
          { key: 'codigo', label: 'Nº Contrato' },
          { key: 'proposta.segurado.tomador.nomeCompleto', label: 'Nome Tomador' },
          { key: 'proposta.segurado.matricula', label: 'Matrícula' },
          {
            key: 'proposta.valorComIOF',
            label: 'Valor do Contrato',
            formatter: (value) => {
              return formatarValorReais(value)
            },
          },
          {
            key: 'taxas',
            label: 'Taxas',
            formatter: (value) => {
              return formatarValorPorcentagem(value)
            },
          },
          {
            key: 'valorParcela',
            label: 'Valor da Parcela',
            formatter: (value) => {
              return formatarValorReais(value)
            },
          },
          { key: 'numeroParcelas', label: 'Nº de Parcelas' },
          {
            key: 'createdAt',
            label: 'Data de início',
            formatter: (value, key, item) => {
              return formatarDataWTime(value)
            },
          },
          'statusFinanceiro',
          { key: 'proposta.segurado.orgao.nome', label: 'Nome do Órgão' },
        ],
        categoriaOpcoes: [
          { value: 'TODOS', text: 'TODOS' },
          { value: 'Efetivo', text: 'Efetivo' },
          { value: 'Pensionista', text: 'Pensionista' },
          { value: 'Inativo', text: 'Aposentado' },
          { value: 'Estavel', text: 'Estavel' },
        ],
        vendedores: [],
        vendedor: null,
      }
    },
    mounted() {
      let dataAtual = new Date()
      this.pesquisar.dataInicial = formatarData(new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, 1))
      this.pesquisar.dataFinal = formatarData(new Date())
      this.carregarVendedores()
    },

    methods: {
      carregarVendedores() {
        useJwt
          .get(`cadastro/user/buscaVendedores/${this.userData.institutoSelecionado}`)
          .then((response) => {
            const vendedores = response.data
            this.vendedores = vendedores
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async fetchContratos(aux) {
        const res = await useJwt.pesquisar('financeiro/GetRelatorioContrato', aux).catch((error) => {
          this.isBusy = false
          console.error(error)
        })
        return res.data
      },
      carregarGrid() {
        this.$refs.formPesquisar.validate().then(async (success) => {
          if (success) {
            this.isBusy = true
            const data = await this.fetchContratos({
              ...this.pesquisar,
              valorContrato: this.pesquisar.valorBaseCalculo + this.pesquisar.valorIOF ?? null,
              institutoSelecionado: this.userData.institutoSelecionado,
              statusOpcoes: this.pesquisar.statusOpcoes ?? null,
              descricao: this.pesquisar.descricao ?? null,
              categoria: this.categoria,
              usuarioId: this.vendedor?.id ?? null,
            })
            this.items = data.dados

            if (!this.items.length) {
              this.$message.error('Não há dados com base no filtro selecionado!')
            }

            this.totals = {
              rows: data.total,
              consolidados: data.consolidado,
              pendentes: data.pendente,
              valorTotal: data.valorTotal,
            }
            this.isBusy = false
          }
        })
      },
      async exportTable(mode) {
        const data = await this.fetchContratos({
          ...this.pesquisar,
          valorContrato: this.pesquisar.valorBaseCalculo + this.pesquisar.valorIOF ?? null,

          institutoSelecionado: this.userData.institutoSelecionado,
          statusOpcoes: this.pesquisar.statusOpcoes ?? null,
          descricao: this.pesquisar.descricao ?? null,
          export: true,
          categoria: this.categoria,
        })

        if (mode === 'pdf') {
          this.parametroPDF = {
            dados: data.dados,
            rows: data.total,
            colunas: this.fieldsPDF,
          }
          let self = this
          setTimeout(function () {
            self.exportToPDF(self.parametroPDF)
          }, 3000)
        } else if (mode === 'excel') {
          this.exportExcel(data.dados)
        }
      },
      exportToPDF(parametroPDF) {
        if (!parametroPDF.rows > 0) {
          this.$message.error('Não há dados para exportar')
          return
        }
        if (this.$refs.tableRelatoriosContratos) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          htmlContent += `<br><div style="font-size: x-small;"><b>Valor total de contratos:</b> ${this.totals.valorTotal}</div>`
          let periodoFiltro = `Período: ${this.pesquisar.dataInicial} a ${this.pesquisar.dataFinal}`
          let nomeRelatorio = `<div>Relatório de Contratos</div>`

          generateAndDownloadPdf(this, htmlContent, periodoFiltro, nomeRelatorio, null, this.userData.institutoSelecionado)
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportExcel(items) {
        try {
          this.$message.warning('Aguarde, estamos gerando o relatório')
          asDefault().export(items)
        } catch (error) {
          this.$message.error('Ocorreu um erro ao gerar o relatório', 'error')
          console.error(error)
        }
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.pesquisar.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      'pesquisar.dataInicial'(newVal) {
        if (!newVal) {
          this.dataInicialDatePicker = null
        }
      },
      dataFinalDatePicker(newVal) {
        this.pesquisar.dataFinal = formatarData(this.dataFinalDatePicker)
      },
      'pesquisar.dataFinal'(newVal) {
        if (!newVal) {
          this.dataFinalDatePicker = null
        }
      },
      'pesquisar.currentPage': function () {
        this.carregarGrid()
      },
      'pesquisar.perPage': function () {
        this.carregarGrid()
      },
    },
  }
</script>

<style></style>
