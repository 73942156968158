<template>
  <div class="cardRelatorio">
    <b-row>
      <b-col cols="2">
        <b-card-header
          header="Contrato"
          header-bg-variant="primary"
          header-text-variant="white"
          header-class="text-center text-lg p-1"
        />
        <b-card>
          <b-card-text>
            <b-form-group class="mb-1">
              <b-form-checkbox v-model="optionsLocal.Financeiro" @change="marcarDesmarcarHandler('Financeiro')">
                Acessar
              </b-form-checkbox>
            </b-form-group>
            <div style="border: 1px solid" class="mb-1"></div>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroContratosConsulta">Consultar</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroContratosPendentes">Contratos Pendentes</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroContratosConsolidados">Contratos Consolidados</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroAnexarArquivos">Anexar Arquivos</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroExcluirArquivos">Excluir Arquivos</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroConsultarDocumentos">Consultar Documentos</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="optionsLocal.FinanceiroInformacoesAvancadas">Informações Avançadas</b-form-checkbox>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-1" @click.prevent="authESalve">
          Salvar
        </b-button>
      </b-col>
    </b-row>
    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'

  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import cryptUserData from '@/@core/auth/utils/Crypt-User-Data'

  export default {
    components: {
      AutenticaSenha,
      ToastificationContent,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        cryptUserData,
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      }
    },
    methods: {
      CFinanceiro() {
        const value = this.optionsLocal.Financeiro
        this.optionsLocal = {
          ...this.optionsLocal,
          FinanceiroContratosConsulta: value,
          FinanceiroContratosPendentes: value,
          FinanceiroContratosConsolidados: value,
          FinanceiroAnexarArquivos: value,
          FinanceiroExcluirArquivos: value,
          FinanceiroConsultarDocumentos: value,
          FinanceiroInformacoesAvancadas: value,
        }
      },
      CAssinatura() {
        const value = this.optionsLocal.FinanceiroAssinaturas
        this.optionsLocal = {
          ...this.optionsLocal,
          FinanceiroAssinaturasConsulta: value,
          FinanceiroAlterarAssinaturas: value,
          FinanceiroAssinaturaDigital: value,
        }
      },
      authESalve() {
        this.optionsLocal.mode = 'authESalve'
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.optionsLocal.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        const parametro = {
          ...this.optionsLocal,
        }

        useJwt
          .update(`grupo-acesso/putGrupoContrato`, this.optionsLocal.id, parametro)
          .then((response) => {
            const userDataLocal = this.$jwtDecode(localStorage.getItem('userData')).userData
            userDataLocal.grupoAcesso = response.data
            this.cryptUserData(userDataLocal)

            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      marcarDesmarcarHandler(tipo) {
        if (tipo == 'AssinaturaDigital') {
          this.CAssinatura()
        }
        if (tipo == 'Financeiro') {
          this.CFinanceiro()
        }
      },
    },
  }
</script>

<style scoped>
  .text-lg {
    font-size: 1.3rem;
  }
</style>
